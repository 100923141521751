import React, {useState} from "react";
import gql from "graphql-tag";
import {Mutation, Query} from "react-apollo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Field, Form, Formik} from "formik";
import {client} from '../../apollo/client';
import MyAccountLayout from "../../components/myAccountLayout";

const PARTNER_PROFILE = gql`
    query PartnerProfile {
        partnerProfile {
            name
            address
            address2
            city
            state
            zip
            country
            phone
            category
            description
            contactPerson
            motherCompany
            images {
                id
                url
            }
        }
    }
`

const UPSERT_PARTNER_PROFILE = gql`
    mutation UpsertPartnerProfile($input:PartnerProfileInput!) {
        upsertPartnerProfile(input:$input){
            zip
        }
    }`

const initPartnerProfile = {
  name: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  phone: "",
  category: "",
  description: "",
  contactPerson: "",
  images: "",
}


const TField = ({name, placeholder, ...rest}) => {
  return <Field name={name}
                className='font-sans font-light border flex-1 my-1 mr-1 p-1'
                placeholder={placeholder} {...rest}/>;
}

const PartnerProfile = () => {
  const [images, setImages] = useState([])

  return <MyAccountLayout>
    <div className='flex flex-col mx-64 mt-16 mb-32 items-start'>
      <h1
        className='font-title text-4xl uppercase mt-8 mb-2 text-yellow-700 text-center tracking-wide mb-4'>Account
        details</h1>

      <Query
        client={client}
        query={PARTNER_PROFILE}>{({loading, data}) => {
        if (loading) {
          return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
        }
        const partnerProfile = data.partnerProfile;
        return <Mutation
          client={client}
          mutation={UPSERT_PARTNER_PROFILE}
        >
          {(upsertPartnerProfile) => (
            <Formik
              initialValues={partnerProfile ? partnerProfile : initPartnerProfile}
              onSubmit={async ({recaptcha, ...input}, {setSubmitting, setStatus}) => {
                setStatus(null)
                input.images = images.map(i => ({id: i.id, file: i.file}))
                delete input.__typename
                upsertPartnerProfile({variables: {input}})
                  .then(r => {
                    setStatus({error: false});
                    setSubmitting(false);
                  })
                  .catch(e => {
                    console.error(e)
                    setStatus({error: true});
                    setSubmitting(false);
                  })
              }}
            >
              {({isSubmitting, status}) => (<Form className='w-full pt-4 pb-2 flex flex-col'>
                <div className='w-full flex'>
                  <TField name='name' placeholder='Company name'/>
                  <TField name='category' placeholder='Category'/>
                </div>
                <TField name='description' placeholder='Description'/>
                <TField name='contactPerson' placeholder='Contact person'/>
                <TField name='address' placeholder='Address line 1'/>
                <TField name='address2' placeholder='Address line 2'/>
                <div className='w-full flex'>
                  <TField name='country' placeholder='Country'/>
                  <TField name='city' placeholder='City'/>
                  <TField name='state' placeholder='State'/>
                </div>
                <div className='w-full flex'>
                  <TField name='zip' placeholder='Zip code'/>
                  <TField name='phone' placeholder='Contact number'/>
                </div>
                <div className="w-full flex">
                  <TField name='motherCompany' placeholder='Mother company'/>
                </div>
                <div className='pt-4 flex flex-col justify-center items-start'>
                  <div className="flex flex-wrap">
                    {images.map(im => <div key={im.url} className="relative w-1/2 pr-1 pb-1">
                      <img src={`${im.url}`} alt=""/>
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setImages(images.filter(i => i.url !== im.url))
                        }}
                        className='uppercase absolute right-0 top-0 z-50 py-1 mr-1 px-2 focus:outline-none bg-red-600 font-title font-light text-white text-center tracking-wide text-lg'>
                        Delete
                      </button>
                    </div>)}
                  </div>
                  <label className='flex justify-center mt-1 w-full' htmlFor="imagesInput">
                          <span
                            className="w-full mt-4 text-center text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase">Upload images</span>
                    <input type="file" id="imagesInput" className='hidden' multiple={true}
                           onChange={async ({target: {validity, files}}) => {
                             const fs = Array.from(files)
                             const ps = fs.map(file => {
                               const reader = new FileReader()
                               const p = new Promise((resolve, reject) => reader.onload = e => resolve({
                                 file,
                                 url: e.target.result
                               }))
                               reader.readAsDataURL(file)
                               return p
                             })
                             const result = await Promise.all(ps)
                             setImages([...images, ...result])
                             return validity.valid
                           }}
                    />
                  </label>

                </div>
                <button
                  className='my-4 text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'
                  type="submit" disabled={isSubmitting}>
                  Submit
                </button>
                {isSubmitting ?
                  <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/> : null}
                <div
                  className={'my-2 w-full font-sans font-light py-2 px-8 ' + (status ? (status.error ? 'bg-red-300' : 'bg-green-300') : 'invisible')}>{status && status.error ? 'Something went wrong, please try again later' : 'Your style report was saved successfully'}</div>
              </Form>)}
            </Formik>
          )}
        </Mutation>
      }}</Query>
    </div>
  </MyAccountLayout>;
}

export default PartnerProfile